<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main">
    <v-form>
      <!-- ENDERECO -->
      <!-- CEP -->
      <v-row no-gutters>
        <v-col class="form-header text-center">
          <div>Endereço</div>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          CEP
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input class="width-180" id="cep" name="cep" type="text" v-mask="masks.cep" v-model="cep" />
          <a @click="consultar" class="btn-azul btn-padrao" id="btn-consultar">Consultar</a>
          <a class="btn-azul btn-padrao" href="http://www.buscacep.correios.com.br/servicos/dnec/index.do"
            target="_blank" title="Localizar CEP - Correios">Localizar CEP - Correios</a>
        </v-col>
      </v-row>

      <!-- Logradouro -->
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Logradouro
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row align-center">
              <select class="width-180" id="cmbTipoLogradouro" name="cmbTipoLogradouro" v-model="tipoLogradouro">
                <option :key="item" :value="item" v-for="item in tiposLogradouros">{{ item }}</option>
              </select>
            </div>
            <div class="d-flex flex-row align-center">
              <input class="text-uppercase width-300" id="logradouro" maxlength="50" name="logradouro" size="55"
                type="text" v-model="logradouro" />
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Complemento / Número -->
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Complemento
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="4">
          <input class="form-input-text text-uppercase width-180" id="complemento" maxlength="15" name="complemento"
            size="13" type="text" v-model="complemento" />
        </v-col>

        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">
          Número
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <input class="form-input-text text-uppercase width-180" id="numero" maxlength="7" name="numero" size="5"
            type="text" v-model="numero" />
        </v-col>
      </v-row>

      <!-- Bairro -->
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Bairro
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input class="text-uppercase" id="bairro" maxlength="40" name="bairro" size="45" type="text"
            v-model="bairro" />
        </v-col>
      </v-row>

      <!-- UF / Município -->
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          UF
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="4">
          <input class="form-input-text text-uppercase width-180" disabled id="cmbUFEndereco" maxlength="15"
            name="cmbUFEndereco" size="13" type="text" v-model="uf" />
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">
          Município
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <input class="form-input-text text-uppercase width-215" disabled id="cmbMunicipioEndereco"
            name="cmbMunicipioEndereco" size="50" type="text" v-model="cidade" />
        </v-col>
      </v-row>

      <!-- Tipo de Imóvel / Ocupação do Imóvel -->
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Tipo de Imóvel
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="4">
          <select class="width-180" id="cmbTipoImovel" name="cmbTipoImovel" v-model="tipoImovel">
            <option :key="item" :value="item" v-for="item in tiposImoveis1">{{ item }}</option>
          </select>
        </v-col>

        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">
          Ocupação do Imóvel
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <select class="width-215" id="cmbTipoOcupacaoImovel" name="cmbTipoOcupacaoImovel" v-model="ocupacaoImovel">
            <option :key="item" :value="item" v-for="item in tiposOcupacaoImovel">{{ item }}</option>
          </select>
        </v-col>
      </v-row>

      <!-- Comprovante Residência -->
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Comprovante Residência
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input id="comprovanteResidenciaMes" maxlength="2" name="comprovanteResidenciaMes" size="1" type="text"
            v-model="comprovanteResidenciaMes" />
          <span class="mx-2">/</span>
          <input id="comprovanteResidenciaAno" maxlength="4" name="comprovanteResidenciaAno" size="2"
            style="width: 100px" type="text" v-model="comprovanteResidenciaAno" />
        </v-col>
      </v-row>

      <!-- TELEFONES -->
      <!-- Telefone de Contato -->
      <v-row class="mt-3" no-gutters>
        <v-col class="form-header text-center">
          <div>Telefones</div>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="6">
          Telefone de Contato
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="6">
          <input class="mr-2 width-30" id="dddRes" name="dddRes" maxlength="2" type="text" size="2"
            v-mask="masks.phone.ddd" v-model="contatoDdd" />
          <input class="width-180" id="telefoneRes" name="telefoneRes" maxlength="10" minlength="9" type="tel"
            v-mask="[masks.phone.number1, masks.phone.number2]" v-model="contatoTelefone" />
        </v-col>
      </v-row>

      <!-- Telefone Celular -->
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="6">
          Telefone Celular
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="6">
          <input class="mr-2 width-30" id="dddCel" name="dddCel" maxlength="2" type="text" size="2"
            v-mask="masks.phone.ddd" v-model="celularDdd" />
          <input class="width-180" id="telefoneCel" name="telefoneCel" maxlength="10" minlength="9" type="tel"
            v-mask="[masks.phone.number1, masks.phone.number2]" v-model="celularTelefone" />
        </v-col>
      </v-row>

      <!-- EMAIL -->
      <v-row class="mt-3" no-gutters>
        <v-col class="form-header text-center">
          <div>E-mail</div>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col class="text-center">
          <input class="text-uppercase width-300" id="email" maxlength="50" name="email" size="50" type="text"
            v-model="email" />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import _ from "lodash";
import { createNamespacedHelpers } from "vuex";
import axios from "axios";
import { mapClienteFields } from "../../../store/modules/caixaqui-clientes";
import { mapDadosGeraisFields } from "../../../store/modules/dados-gerais";
import { required } from "vuelidate/lib/validators";
import { isValid, validadteDDDSizeIf, requiredInt, validatePhoneSizeIf } from "../../../validators";
import { OPTIONS } from "../../../config";

const { mapGetters: mapDadosGeraisGetters } = createNamespacedHelpers("dadosGerais");

export default {
  name: "CaixaquiCadastroClientesEndereco",

  data: () => ({
    masks: OPTIONS.masks,
    style: {
      colLeft: 2,
      colRight: 10,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    validationMessages: {
      cep: { required: "Digite um CEP válido." },
      numero: { required: "Número inválido." },
      tipoImovel: { required: "Selecione o tipo de imóvel." },
      ocupacaoImovel: { required: "Selecione o tipo de ocupação do imóvel." },
      comprovanteResidenciaMes: { requiredInt: "Comprovante de Residencia - Mês inválido." },
      comprovanteResidenciaAno: { requiredInt: "Comprovante de Residencia - Ano inválido." },
      contatoDdd: { required: "DDD do Telefone de Contato inválido." },
      contatoTelefone: {
        required: "Fone de Contato inválido.",
        integer: "O Fone de Contato precisa de no mínimo 8 digitos."
      },
      celularDdd: { required: "DDD do Telefone Celular inválido." },
      celularTelefone: {
        required: "Preencha corretamente o fone celular.",
        integer: "Informar 8 dígitos (ou 9 dígitos no caso de São Paulo) no telefone celular."
      },
      prazoTotal: {
        requiredInt: "Preencha o número."
      }
    }
  }),
  validations() {
    return {
      cep: { required },
      numero: { required },
      tipoImovel: { required },
      ocupacaoImovel: { required },
      comprovanteResidenciaMes: { requiredInt },
      comprovanteResidenciaAno: { requiredInt },
      contatoDdd: { required: validadteDDDSizeIf(this.contatoTelefone) },
      contatoTelefone: {
        required,
        integer: validatePhoneSizeIf()
      },
      celularDdd: { required: validadteDDDSizeIf(this.celularTelefone) },
      celularTelefone: {
        integer: validatePhoneSizeIf(this.celularTelefone)
      },
      numero: { requiredInt },
    };
  },
  computed: {
    ...mapClienteFields([
      "endereco.cep",
      "endereco.tipoLogradouro",
      "endereco.logradouro",
      "endereco.complemento",
      "endereco.numero",
      "endereco.bairro",
      "endereco.uf",
      "endereco.cidade",
      "endereco.tipoImovel",
      "endereco.ocupacaoImovel",
      "endereco.comprovanteResidenciaMes",
      "endereco.comprovanteResidenciaAno",
      "email"
    ]),
    ...mapClienteFields({
      contatoDdd: "telefones.contato.ddd",
      contatoTelefone: "telefones.contato.telefone",
      celularDdd: "telefones.celular.ddd",
      celularTelefone: "telefones.celular.telefone"
    }),
    ...mapDadosGeraisFields(["tiposLogradouros", "tiposImoveis1", "tiposOcupacaoImovel"]),
    ...mapDadosGeraisGetters(["separateTypeAndAddress"])
  },
  methods: {
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        if (this.comprovanteResidenciaMes.length < 2)
          this.comprovanteResidenciaMes = "0" + this.comprovanteResidenciaMes;
        if (isValid(this.$v, this.validationMessages)) {
          this.$router.push(to);
        }
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    isCepValido(cep) {
      return cep && /\d{8}/g.test(cep);
    },
    consultar() {
      const cleanCep = _.replace(this.cep, "-", "");
      if (this.isCepValido(cleanCep)) {
        axios
          .get(`https://viacep.com.br/ws/${cleanCep}/json/`)
          .then(res => {
            this.$log.debug("Consulta cep:", res);
            const result = res.data;

            if (result.erro) {
              throw result;
            } else {
              let endereco = this.separateTypeAndAddress(result.logradouro);
              this.tipoLogradouro = endereco.tipoLogradouro;
              this.logradouro = endereco.logradouro.trim();
              this.bairro = result.bairro;
              this.uf = result.uf;
              this.cidade = result.localidade;
              this.complemento = null;
              this.numero = null;
            }
          })
          .catch(reason => {
            this.$log.debug("error:", reason);
            this.tipoLogradouro = null;
            this.logradouro = null;
            this.bairro = null;
            this.uf = null;
            this.cidade = null;
            this.complemento = null;
            this.numero = null;
            alert("CEP não localizado.");
          });
      } else {
        alert("Digite um CEP válido.");
      }
    }
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css">
</style>
<style scoped>
#cep {
  margin-right: 4px;
}

#btn-consultar {
  margin-right: 4px;
}

#email {
  margin: 2px 0 2px 0;
}
</style>
