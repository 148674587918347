import { render, staticRenderFns } from "./CaixaquiCadastroClientesEndereco.vue?vue&type=template&id=842fb43c&scoped=true&"
import script from "./CaixaquiCadastroClientesEndereco.vue?vue&type=script&lang=js&"
export * from "./CaixaquiCadastroClientesEndereco.vue?vue&type=script&lang=js&"
import style0 from "../../../components/caixaqui/caixaqui-style.css?vue&type=style&index=0&id=842fb43c&scoped=true&lang=css&"
import style1 from "./CaixaquiCadastroClientesEndereco.vue?vue&type=style&index=1&id=842fb43c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "842fb43c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('842fb43c')) {
      api.createRecord('842fb43c', component.options)
    } else {
      api.reload('842fb43c', component.options)
    }
    module.hot.accept("./CaixaquiCadastroClientesEndereco.vue?vue&type=template&id=842fb43c&scoped=true&", function () {
      api.rerender('842fb43c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/caixaqui/cadastro-clientes/CaixaquiCadastroClientesEndereco.vue"
export default component.exports